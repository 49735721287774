export const getQueryParam = (uri, param) => {
  if (!uri) return

  return (uri.startsWith("http")
    ? new URLSearchParams(new URL(uri).search)
    : uri.startsWith("/") || uri.startsWith("?")
    ? new URLSearchParams(uri.split("?")[1])
    : new URLSearchParams(uri)
  ).get(param)
}

export const queryString = (queryParams = {}) => {
  const noEmptyParams = Object.keys(queryParams)
    .filter(k => queryParams[k])
    .reduce((r, k) => ({ ...r, [k]: queryParams[k] }), {})
  const result = new URLSearchParams(noEmptyParams).toString()
  return result ? `?${result}` : ""
}

export const getHashValue = hash =>
  hash && typeof hash === "string" && hash.startsWith("#")
    ? hash.substring(1)
    : hash

export const getLastHash = hash => {
  const list = toHashList(hash)
  return list[list.length - 1]
}

export const toHashList = hashString => {
  const hash = hashString || ""

  return getHashValue(hash)
    .split("+")
    .filter(t => !!t)
    .map(hash => hash.split("?")[0])
}

export const addToHash = (currentHash, hash) => {
  const list = toHashList(currentHash)
  const hashString = getHashValue(hash)

  return `#${
    list.includes(hashString) ? hashString : [...list, hashString].join("+")
  }`
}

export const removeFromHash = (currentHash, hash) => {
  const list = toHashList(currentHash)
  const hashString = getHashValue(hash)
  return `#${list.filter(h => h !== hashString).join("+")}`
}

export const getPathFromUrl = (url, inclusive) => {
  const urlObj = url.startsWith("http")
    ? new URL(url)
    : new URL(`https://example.com${url}`)

  return inclusive
    ? urlObj.pathname + urlObj.search + urlObj.hash
    : urlObj.pathname
}

export const normalizeUrl = url =>
  url ? url.replace(/^(http:|https:)?\/\//, "") : null

export const urlCompare = (url1, url2) => {
  return normalizeUrl(url1) === normalizeUrl(url2)
}
