import Vue from "vue"

import { isPresent, isEmpty } from "@/lib/utils"
import ApiClient from "@/lib/api-client"

import {
  buildMessageId,
  buildMessageFromPusherData,
  groupMessages,
  sortMessagesAsPosts,
  visibleMessages,
  MESSAGE_ORDER
} from "@/lib/messages-utils"

import {
  subscribeToThread,
  unsubscribeFromThread,
  publishToThread,
  removeFromThread,
  reactMessage,
  MESSAGE_TYPES,
  REACTION_TYPES
} from "@/lib/pusher-utils"

const getInitialState = () => ({
  messageThreads: {},
  messages: {},
  messagesGrouped: {},
  replyingMessages: {},
  messagingProfiles: {},
  appMessagingProfiles: [],
  pusherSubscriptions: {},
  participants: {},
  globalUnreadStatus: false,
  globalChannel: false
})

const handleCallback = (state, messageThreadId, event) => message =>
  Object.values(
    state.pusherSubscriptions[messageThreadId]?.callbacks || {}
  ).map(cb => (cb[event] ? cb[event](message) : null))

const setupThreadSubscription = (state, commit, messageThreadId) => {
  const messageThread = state.messageThreads[messageThreadId]

  return subscribeToThread(messageThread.channel_id, {
    onMessage: event => {
      commit(
        "pushMessageToThread",
        buildMessageFromPusherData(messageThreadId, event)
      )

      return handleCallback(state, messageThreadId, "onMessage")(event)
    },
    onMessageDeleted: event => {
      commit("deleteMessageFromThread", {
        messageThreadId,
        message: buildMessageFromPusherData(messageThreadId, event)
      })

      return handleCallback(state, messageThreadId, "onMessageDeleted")(event)
    },
    onMessageUpdated: event => {
      commit(
        "updateMessage",
        buildMessageFromPusherData(messageThreadId, event)
      )

      return handleCallback(state, messageThreadId, "onMessageUpdated")
    },
    onMessageReaction: event => {
      commit("updateMessageReactions", {
        messageThreadId,
        data: {
          action: event.action,
          userId: event.user.id,
          messageTs: event.messageTs
        }
      })

      return handleCallback(state, messageThreadId, "onMessageReaction")
    },
    onParticipantRemoved: event => {
      commit("removeParticipantFromThread", {
        messageThreadId: event.messageThreadId,
        userId: event.userId,
        title: event.messageThreadTitle
      })

      return handleCallback(
        state,
        messageThreadId,
        "onParticipantRemoved"
      )(event)
    },
    onThreadUpdated: event => {
      commit("setMessageThread", {
        id: event.messageThreadId,
        title: event.messageThreadTitle
      })

      return handleCallback(state, messageThreadId, "onThreadUpdated")(event)
    },
    onParticipantAdded: event => {
      commit("addParticipantToThread", {
        messageThreadId: event.messageThreadId,
        participant: event.participant
      })
      return handleCallback(state, messageThreadId, "onParticipantAdded")(event)
    },
    onSubscribe: handleCallback(state, messageThreadId, "onSubscribe"),
    onMemberAdded: handleCallback(state, messageThreadId, "onMemberAdded"),
    onMemberRemoved: handleCallback(state, messageThreadId, "onMemberRemoved")
  })
}

export default {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    setMessageThreads(state, { message_threads }) {
      if (message_threads)
        state.messageThreads = [...state.messageThreads, ...message_threads]
    },

    setReplyingMessage(state, { threadId, message }) {
      Vue.set(state.replyingMessages, threadId, message)
    },

    setMessagingProfile(state, { id, profile }) {
      Vue.set(state.messagingProfiles, id, profile)
    },

    setAppMessagingProfiles(state, profiles) {
      state.appMessagingProfiles = profiles
    },

    setMessageThread(state, messageThread) {
      Vue.set(state.messageThreads, messageThread.id, {
        ...(state.messageThreads[messageThread.id] || {}),
        ...messageThread
      })
    },

    removeMessageThread(state, messageThreadId) {
      Vue.delete(state.messageThreads, messageThreadId)
    },

    removeParticipantFromThread(state, { messageThreadId, userId, title }) {
      const messageThread = state.messageThreads[messageThreadId] || {}
      const updatedParticipants = (messageThread.participants || []).filter(
        p => p.user?.id != userId
      )
      const newTitle =
        !!messageThread.is_private && updatedParticipants.length === 1
          ? updatedParticipants[0].name
          : title

      Vue.set(state.messageThreads, messageThreadId, {
        ...messageThread,
        ...{
          title: newTitle || messageThread.title,
          participants: updatedParticipants,
          total_count_participants:
            (messageThread.total_count_participants || 1) - 1
        }
      })
    },

    setGlobalUnreadStatus(state, hasUnread) {
      state.globalUnreadStatus = hasUnread
    },

    setGlobalChannel(state, channel) {
      state.globalChannel = channel
    },

    setMessages(state, { messageThreadId, messages }) {
      Vue.set(state.messages, messageThreadId, {
        ...(state.messages[messageThreadId] || {}),
        ...messages.reduce((acc, m) => {
          acc[parseInt(m.message_ts)] = m
          return acc
        }, {})
      })

      Vue.set(
        state.messagesGrouped,
        messageThreadId,
        groupMessages(Object.values(state.messages[messageThreadId]))
      )
    },

    setParticipant(state, { messageThreadId, participant }) {
      const _participant = state.participants[messageThreadId] || {
        liked_messages: [],
        user: {}
      }

      Vue.set(state.participants, messageThreadId, {
        ..._participant,
        ...participant
      })
    },

    addParticipantToThread(state, { messageThreadId, participant }) {
      const messageThread = state.messageThreads[messageThreadId] || {}
      const participants = messageThread.participants || []
      const currentParticipant = participants.filter(
        s => s.user.id == participant.user.id
      )
      if (!isPresent(currentParticipant)) {
        participants.push(participant)
        Vue.set(state.messageThreads, messageThreadId, {
          ...messageThread,
          ...{
            participants: participants,
            total_count_participants: messageThread.total_count_participants + 1
          }
        })
      }
    },

    setPusherSubscription(
      state,
      { messageThreadId, subscription, subscriptionId, callbacks }
    ) {
      const existingSubscription = state.pusherSubscriptions[messageThreadId]
      const args = {
        subscription,
        callbacks: existingSubscription?.callbacks || {}
      }

      if (
        !existingSubscription ||
        !existingSubscription.callbacks[subscriptionId]
      ) {
        args.callbacks[subscriptionId] = callbacks
      }

      // Because we are re-using the Pusher subscription, we need to mock the
      // callback for onSubscribe otherwise it can cause it to not be called
      if (
        existingSubscription &&
        callbacks["onSubscribe"] &&
        existingSubscription.subscription.presenceChannel
      ) {
        callbacks["onSubscribe"](
          existingSubscription.subscription.presenceChannel.members
        )
      }

      Vue.set(state.pusherSubscriptions, messageThreadId, args)
    },

    removeSubscriptionCallbacks(state, { messageThreadId, subscriptionId }) {
      if (
        state.pusherSubscriptions[messageThreadId] &&
        state.pusherSubscriptions[messageThreadId].callbacks[subscriptionId]
      ) {
        delete state.pusherSubscriptions[messageThreadId].callbacks[
          subscriptionId
        ]
      }
    },

    pushMessageToThread(state, message) {
      message.reaction_count = 0

      const thread = state.messageThreads[message.message_thread_id]

      const threadArgs = {
        message_count: (thread.message_count || 0) + 1,
        latest_message_ts: message.message_ts,
        latest_message: message
      }

      if (
        message.user?.id &&
        !(thread.participants || []).find(p => p.user.id === message.user.id)
      ) {
        threadArgs.participants = [
          ...(thread.participants || []),
          { user: message.user }
        ]
      }

      Vue.set(state.messageThreads, message.message_thread_id, {
        ...thread,
        ...threadArgs
      })

      this.commit("messages/setMessages", {
        messageThreadId: message.message_thread_id,
        messages: [message]
      })

      const participant = state.participants[message.message_thread_id]

      if (
        isPresent(message.user) &&
        isPresent(participant.user) &&
        message.user.id === participant.user.id &&
        !participant.has_contributed
      ) {
        this.commit("messages/setParticipant", {
          messageThreadId: message.message_thread_id,
          participant: {
            ...participant,
            has_contributed: true
          }
        })
      }
    },

    pushMessageThread(state, { messageThread }) {
      const messageThreads = state.messageThreads.filter(
        currentMessageThread => currentMessageThread.id !== messageThread.id
      )

      messageThreads.unshift(messageThread)

      state.messageThreads = messageThreads
    },

    updateMessageReactions(state, { messageThreadId, data }) {
      const messageToUpdate = state.messages[messageThreadId][data.messageTs]

      if (!messageToUpdate) return

      switch (data.action) {
        case REACTION_TYPES.LIKE:
          messageToUpdate.reaction_count += 1

          break
        case REACTION_TYPES.UNLIKE:
          messageToUpdate.reaction_count += -1

          break
        default:
          return
      }

      this.commit("messages/setMessages", {
        messageThreadId,
        messages: [messageToUpdate]
      })
    },

    updateMessage(state, message) {
      Vue.set(
        state.messages[message.message_thread_id],
        message.message_ts,
        message
      )

      Vue.set(
        state.messagesGrouped,
        message.message_thread_id,
        groupMessages(Object.values(state.messages[message.message_thread_id]))
      )
    },

    deleteMessageFromThread(state, { messageThreadId, message }) {
      if (
        state.messages[messageThreadId] &&
        state.messages[messageThreadId][message.message_ts]
      ) {
        Vue.delete(state.messages[messageThreadId], message.message_ts)

        Vue.set(
          state.messagesGrouped,
          messageThreadId,
          groupMessages(Object.values(state.messages[messageThreadId]))
        )
      }

      const thread = state.messageThreads[messageThreadId]
      const messages = state.messages[messageThreadId]

      const newThreadArgs = {
        ...thread,
        message_count: (thread.message_count || 1) - 1
      }

      if (thread.latest_message_ts === message.message_ts) {
        const latestMessage = Object.keys(messages).sort((a, b) => b - a)[0]

        newThreadArgs.latest_message_ts = latestMessage
        newThreadArgs.latest_message = messages[latestMessage]
      }

      Vue.set(state.messageThreads, message.message_thread_id, newThreadArgs)
    },

    reset(state) {
      if (state.globalChannel) {
        unsubscribeFromThread(state.globalChannel)
      }

      // Merge rather than replace so we don't lose observers
      // https://github.com/vuejs/vuex/issues/1118
      Object.assign(state, getInitialState())
    },

    updatePinnedAt(state, { messageThreadId, messageTs, pinnedAt }) {
      const messageToUpdate = state.messages[messageThreadId][messageTs]

      if (!messageToUpdate) return

      messageToUpdate.pinned_at = pinnedAt
    }
  },

  actions: {
    async subscribeToThread(
      { state, dispatch, commit },
      { subscriptionId, messageThreadId, callbacks = {} }
    ) {
      if (!isPresent(subscriptionId)) throw "ID required to identify callbacks"

      if (!state.messageThreads[messageThreadId]) {
        await dispatch("fetchMessageThread", messageThreadId)
      }

      const existingSubscription =
        state.pusherSubscriptions[messageThreadId]?.subscription

      const subscription =
        existingSubscription ||
        setupThreadSubscription(state, commit, messageThreadId)

      commit("setPusherSubscription", {
        messageThreadId,
        subscription,
        subscriptionId,
        callbacks
      })

      return subscription
    },

    unsubscribeFromThread({ commit }, { subscriptionId, messageThreadId }) {
      commit("removeSubscriptionCallbacks", {
        messageThreadId,
        subscriptionId
      })
    },

    publishToThread({ state, commit }, { messageThreadId, message }) {
      const subscription =
        state.pusherSubscriptions[messageThreadId].subscription

      publishToThread({ channel: subscription.channel, ...message })

      commit(
        "pushMessageToThread",
        buildMessageFromPusherData(messageThreadId, message)
      )
    },

    deleteMessage({ state, commit }, { message, sessionId }) {
      const subscription =
        state.pusherSubscriptions[message.message_thread_id].subscription

      removeFromThread({
        channel: subscription.channel,
        user: message.user,
        messageTs: message.message_ts,
        messageType: message.message_type,
        sessionId: sessionId
      })

      commit("deleteMessageFromThread", {
        messageThreadId: message.message_thread_id,
        message
      })
    },

    removeMessagesByUser({ state, commit }, userId) {
      const messagesToDelete = Object.keys(state.messages).reduce(
        (acc, messageThreadId) => {
          Object.keys(state.messages[messageThreadId]).forEach(messageTs => {
            const message = state.messages[messageThreadId][messageTs]

            if (message && message.user && message.user.id === userId) {
              acc.push(message)
            }
          })

          return acc
        },
        []
      )

      messagesToDelete.forEach(message => {
        commit("deleteMessageFromThread", {
          messageThreadId: message.message_thread_id,
          message
        })
      })
    },

    reactMessage(
      { state, commit, getters },
      { message, action, likedUserId, sessionId }
    ) {
      const subscription =
        state.pusherSubscriptions[message.message_thread_id].subscription

      reactMessage({
        channel: subscription.channel,
        user: message.user,
        messageTs: message.message_ts,
        messageType: MESSAGE_TYPES.MESSAGE_REACTION,
        action,
        likedUserId,
        sessionId
      })

      commit("updateMessageReactions", {
        messageThreadId: message.message_thread_id,
        data: {
          userId: message.user?.id,
          messageTs: message.message_ts,
          action
        }
      })

      const messageToUpdate = getters.getMessage(
        message.message_thread_id,
        message.message_ts
      )

      const participant = getters.getParticipant(message.message_thread_id)

      const likedMessages =
        action === REACTION_TYPES.LIKE
          ? participant.liked_messages.concat(messageToUpdate.message_ts)
          : participant.liked_messages.filter(
              messageTs => messageTs !== messageToUpdate.message_ts
            )

      commit("setParticipant", {
        messageThreadId: message.message_thread_id,
        participant: { liked_messages: likedMessages }
      })
    },

    reportMessage(_, { messageThreadId, messageTs, reason }) {
      return ApiClient.post(
        `/threads/${messageThreadId}/messages/${messageTs}/report`,
        { reason }
      )
    },

    fetchMessageThreads({ commit }, page = 1) {
      return ApiClient.get(`/threads?page=${page}`).then(res => {
        const { message_threads } = res.data

        message_threads.forEach(thread => {
          commit("setMessageThread", thread)

          commit("setParticipant", {
            messageThreadId: thread.id,
            participant: thread.participant
          })

          if (thread.parent_message) {
            commit("setMessages", {
              messageThreadId: thread.id,
              messages: [thread.parent_message]
            })
          }
        })

        return res.data.message_threads
      })
    },

    fetchMessageThread({ commit }, messageThreadId) {
      return ApiClient.get(`/threads/${messageThreadId}`).then(res => {
        const thread = res.data.message_thread
        commit("setMessageThread", thread)
        commit("setParticipant", {
          messageThreadId,
          participant: thread.participant
        })

        if (thread.parent_message) {
          commit("setMessages", {
            messageThreadId: thread.parent_message.message_thread_id,
            messages: [thread.parent_message]
          })
        }

        return res.data
      })
    },

    fetchMessage(_, { messageThreadId, messageTs }) {
      return ApiClient.get(
        `/threads/${messageThreadId}/messages/${messageTs}`
      ).then(res => {
        return res.data.message
      })
    },

    fetchMessages(
      { commit },
      {
        messageThreadId,
        after = null,
        before = null,
        order = "desc",
        parentTs = null
      }
    ) {
      return ApiClient.get(`/threads/${messageThreadId}/messages`, {
        params: {
          before,
          after,
          order,
          parent_ts: parentTs
        }
      }).then(res => {
        commit("setMessages", {
          messageThreadId,
          messages: res.data.messages
        })
        commit("setMessageThread", {
          id: messageThreadId,
          remaining_messages: res.data.remaining_messages
        })
      })
    },

    fetchMessageReactions(_, { messageThreadId, messageTs }) {
      return ApiClient.get(
        `/threads/${messageThreadId}/messages/${messageTs}/reactions`
      ).then(res => res.data)
    },

    createMessageThread(
      { commit },
      { subscriberIds = [], parentMessage, messages = [] }
    ) {
      const messageParams = messages.map(message => {
        const params = {
          message_type: message.messageType,
          created_at: message.messageCreatedAt,
          message_ts: parseInt(message.messageCreatedAt)
        }

        if (message.messageType === MESSAGE_TYPES.MESSAGE) {
          params.message = message.messageBody
        } else if (message.messageType === MESSAGE_TYPES.IMAGE) {
          params.image_ids = message.imageIds
        }

        return params
      })

      return ApiClient.post(`/threads`, {
        subscriber_id: subscriberIds,
        parent_message_id: isPresent(parentMessage)
          ? buildMessageId(parentMessage)
          : null,
        messages: messageParams
      }).then(res => {
        const { message_thread } = res.data

        commit("setMessageThread", message_thread)

        if (parentMessage) {
          commit("setMessages", {
            messageThreadId: parentMessage.message_thread_id,
            messages: [
              {
                ...parentMessage,
                reply_thread_id: message_thread.id
              }
            ]
          })
        }

        commit("setParticipant", {
          messageThreadId: message_thread.id,
          participant: message_thread.participant
        })

        return message_thread
      })
    },

    resolveThread(_, { subscriberIds, router }) {
      if (!isPresent(subscriberIds)) return new Promise((_, reject) => reject())

      return ApiClient.get(`/threads/resolve`, {
        params: { subscriber_ids: subscriberIds }
      }).then(res => {
        if (router) {
          router.push(
            res.data.thread_id
              ? `/me/messages/${res.data.thread_id}`
              : `/me/messages/new?users=${subscriberIds.join(",")}`
          )
        }

        return res.data.thread_id
      })
    },

    fetchMessagingProfiles({ commit }, userIds = []) {
      return ApiClient.get(`/threads/profiles`, {
        params: { user_ids: userIds }
      }).then(({ data }) => {
        data.profiles.forEach(profile => {
          commit("setMessagingProfile", {
            id: profile.id,
            profile: profile
          })
        })

        commit("setAppMessagingProfiles", data.app_profiles)

        return data
      })
    },

    fetchSubscribers(_, { term, ids }) {
      return ApiClient.get(`/subscribers`, {
        params: {
          term,
          ids
        }
      }).then(res => {
        return res.data
      })
    },

    fetchMentionableMembers(_, { messageThreadId, term }) {
      return ApiClient.get(`/threads/${messageThreadId}/mentionable`, {
        params: { term }
      }).then(res => {
        return res.data?.subscribers
      })
    },

    fetchGlobalUnreadStatus({ commit }) {
      return ApiClient.get(`/threads/unread_status`).then(res => {
        commit("setGlobalUnreadStatus", !!res.data.has_unread)
      })
    },

    setMessageThread({ commit }, data) {
      commit("setMessageThread", data)
    },

    removeMessageThread({ commit }, messageThreadId) {
      commit("removeMessageThread", messageThreadId)
    },

    setGlobalChannel({ commit }, channel) {
      commit("setGlobalChannel", channel)
    },

    toggleNotification(
      { commit },
      { messageThreadId, active, toggleTs = +new Date() }
    ) {
      return ApiClient.post(`/threads/${messageThreadId}/toggle_notification`, {
        active,
        toggle_ts: toggleTs
      }).then(res => {
        const active = res.data?.active

        commit("setParticipant", {
          messageThreadId: messageThreadId,
          participant: {
            notifications_enabled: active
          }
        })

        return active
      })
    },

    markRead({ commit }, { messageThreadId, clientTs = +new Date() }) {
      commit("setMessageThread", {
        id: messageThreadId,
        is_unread: false,
        last_message_read_ts: clientTs
      })

      return ApiClient.get(
        `threads/${messageThreadId}/read?client_ts=${clientTs}`
      )
    },

    renameThread({ commit }, { messageThreadId, title }) {
      return ApiClient.post(`threads/${messageThreadId}/rename`, {
        title: title
      }).then(res => {
        commit("setMessageThread", {
          id: messageThreadId,
          title: res.data.title,
          has_been_renamed: true
        })
      })
    },

    removeParticipantFromThread(
      { commit },
      { messageThreadId, userId, isLeaving = false }
    ) {
      return ApiClient.post(`threads/${messageThreadId}/remove_participant`, {
        user_id: userId
      }).then(res => {
        if (isLeaving) {
          commit("removeMessageThread", messageThreadId)
        } else {
          commit("removeParticipantFromThread", {
            messageThreadId: messageThreadId,
            userId: userId,
            title: res.data.new_thread_title
          })
        }
      })
    },

    initializeGlobalChannel({ state, rootState, commit, dispatch }, channel) {
      if (!state.globalChannel) {
        const globalChannel = subscribeToThread(
          channel,
          {
            onMessage: event => {
              if (rootState.subscriber.blockedUserIds.includes(event?.user?.id))
                return

              commit("setGlobalUnreadStatus", true)
            },
            onMemberBlocked: event => {
              commit("subscriber/addUserIdToBlockList", event.userId, {
                root: true
              })

              dispatch("removeMessagesByUser", event.userId)
            }
          },
          false
        )

        commit("setGlobalChannel", globalChannel)
      }
    },

    pinPost({ commit }, { messageThreadId, messageTs, isPinned = false }) {
      return ApiClient.put(`threads/${messageThreadId}/pin`, {
        pinned: isPinned,
        message_ts: messageTs
      }).then(res => {
        commit("updatePinnedAt", {
          messageThreadId: messageThreadId,
          messageTs: res.data.message_ts,
          pinnedAt: res.data.pinned_at
        })
      })
    }
  },

  getters: {
    getReplyingMessageTs(_, getters) {
      return threadId => {
        const replyingMessage = getters.getReplyingMessage(threadId)

        return replyingMessage ? replyingMessage.message_ts : null
      }
    },

    getReplyingMessage(state) {
      return threadId => state.replyingMessages[threadId]
    },

    getMessagingProfileByUserId(state) {
      return userId =>
        Object.values(state.messagingProfiles).find(p => p.user_id == userId) ||
        null
    },

    appMessagingProfilesBySearchTerm(state) {
      return term =>
        state.appMessagingProfiles.filter(subscriber => {
          if (!term) return true

          if (!subscriber || !subscriber.name) return false

          return subscriber.name.includes(term)
        })
    },

    getMessages(state) {
      return ({
        threadId,
        order = MESSAGE_ORDER.LATEST_FIRST,
        grouped = false,
        blockedUserIds = []
      }) => {
        if (isEmpty(state.messages[threadId])) return []

        if (grouped) {
          let messageGroups = state.messagesGrouped[threadId]

          if (isPresent(blockedUserIds)) {
            messageGroups = Object.keys(messageGroups).reduce((acc, key) => {
              const filteredMessages = visibleMessages(
                messageGroups[key] || [],
                blockedUserIds
              )

              if (filteredMessages.length > 0) {
                acc[key] = filteredMessages
              }

              return acc
            }, {})
          }

          if (state.messageThreads[threadId].is_feed) {
            return sortMessagesAsPosts(messageGroups)
          } else {
            const messageGroupKeys = Object.keys(messageGroups)

            const orderedGroupings =
              order === MESSAGE_ORDER.OLDEST_FIRST
                ? messageGroupKeys.sort((a, b) => a - b)
                : messageGroupKeys.sort((a, b) => b - a)

            return orderedGroupings.map(key => messageGroups[key])
          }
        } else {
          const messages = visibleMessages(
            Object.values(state.messages[threadId]),
            blockedUserIds
          )

          return order === MESSAGE_ORDER.OLDEST_FIRST
            ? messages.sort((a, b) => a.message_ts - b.message_ts)
            : messages.sort((a, b) => b.message_ts - a.message_ts)
        }
      }
    },

    getMessage(state) {
      return (threadId, messageTs) => {
        if (isEmpty(state.messages[threadId])) return null

        return state.messages[threadId][parseInt(messageTs)]
      }
    },

    getMessageThreads(state) {
      return Object.values(state.messageThreads)
    },

    getLastMessage(state) {
      return messageThreadId => {
        return isPresent(state.messages[messageThreadId])
          ? state.messages[messageThreadId][
              state.messages[messageThreadId].length - 1
            ]
          : null
      }
    },

    getMessageThread(state) {
      return messageThreadId => state.messageThreads[messageThreadId]
    },

    getParticipant(state) {
      return messageThreadId => state.participants[messageThreadId]
    },

    hasUnreadMessageThreads(state) {
      return !!Object.values(state.messageThreads).find(
        thread => thread.is_unread
      )
    },

    isUnreadAt(_, getters) {
      return (messageThreadId, ts) => {
        const thread = getters.getMessageThread(messageThreadId)

        if (!thread) return false
        if (!thread.last_message_read_ts) return true

        return ts > thread.last_message_read_ts
      }
    },

    isReplying(_, getters) {
      return threadId => !!getters.getReplyingMessage(threadId)
    }
  }
}
